@use 'variables';
@use 'mixins';


.projects{
	@include mixins.default-grid;
    @include mixins.cube-face;
	background: linear-gradient(var(--ivory), var(--teal));

    &__heading{
        @include mixins.page-heading;
        color: var(--blue);
		user-select: none;
    }

	&__card-display{
		@include mixins.default-grid;
		padding: 0 3%;
		grid-column-gap: 3%;
		grid-row-gap: 1rem;
		justify-content: center;
		grid-template-columns: repeat(auto-fit, minmax(14rem, 0.29fr));
		position: relative;

		@media (min-height: 840px) {
            grid-row-gap: 1.5rem;
        }

		@include mixins.medium-screen{
			grid-column: 2/3;
		}
	}

	&__card{
		@include mixins.image-slot;
		padding-bottom: 93%;
		background: var(--teal);
		user-select: none;
		border-radius: 0.7rem;

		@media (min-height: 840px) {
            padding-bottom: 100%;
        }
	}

	&__subheading{
		position: absolute;
		left: 50%;
		top: 40%;
		transform: translate(-50%,-50%);
		display: block;
		font-weight: bold;
		font-size: 1.9rem;
		line-height: 1.2;
		text-align: center;
		// margin: 1rem 0.5rem 0.5rem;
		color: var(--gray);
		text-transform: var(--text-transform);
		font-family: var(--heading-font);

		&:hover{
			color: unset;
		}

		@include mixins.wide-screen{
			font-size: 2.05rem;
		}
	}

	&__caption{
		font-size: 1rem;
		padding: 0 0.75rem;
		line-height: 1.5;
		text-align: center;
		display: none;
	}

	&__link-row{
		position: absolute;
		bottom: 1rem;
		width: 100%;
		display: flex;
		justify-content: space-evenly;
	}

	&__link{
		background-color: var(--coral);
		padding: 10px;
		color: var(--gray);
		font-size: 0.9rem;
		font-weight:bold;
		border-radius: 0.7rem;
		transition: all 200ms ease-in-out;
		color: var(--ivory);

		&:hover{
			background: var(--blue);
			color: unset;
		}
	}

	&__content{
		@include mixins.content-box;
		width: 100%;
        margin-bottom: 18%;

        @media (min-width: 415px) {
            margin-bottom: 13%;
        }

        @include mixins.landscape {
            margin-bottom: 7%;
        }

        @include mixins.medium-screen {
			max-width: 65rem;
            margin-bottom: 5%;
        }

		@include mixins.wide-screen {
            margin-bottom: 6.95%;
        }

		@include mixins.extra-wide-screen {
            margin-bottom: 5.75%;
        }
	}

	.nav-row{
		@include mixins.default-grid;
		grid-column: 1/-1;
	
		&__next{
			@include mixins.projects-nav-button;
			position: absolute;
			top: 46.5%;
			right: -3vw;
			border: 3px solid var(--coral);
			border-radius: 999rem;
			padding: 0.2rem 0.5rem;

			@media (min-width: 415px) {
				@include mixins.desktop-nav-button;
				place-self: center end;
			}
		}

		&__prev{
			@include mixins.projects-nav-button;
			position: absolute;
			top: 46.5%;
			left: -3vw;
			border: 3px solid var(--coral);
			border-radius: 999rem;
			z-index: 1;
			color: var(--coral) !important;
			padding: 0.2rem 0.5rem;

			@media (min-width: 415px) {
				@include mixins.desktop-nav-button;
				place-self: center start;
			}
		}

		&__text{
			display: none;
			font-family: var(--text-font);
			text-transform: unset !important;

			@media (min-width: 415px) {
				display: unset;
			}
		}

		&__right-arrow{

			@media (min-width: 415px) {
				margin-left: 0.5rem;
			}
			
		}

		&__left-arrow{
			@media (min-width: 415px) {
				margin-right: 0.5rem;
			}
		}
	}


}

.dropdown-filters{
	font-size: 1.05rem;
	margin: 0 3%;
	padding: 0.25rem;
	border-radius: 0.7rem;
    background-color: var(--blue);
    color: var(--ivory);
    font-weight: bold;
    font-family: var(--text-font);
	place-self: center center;
	outline: none;
	user-select: none;
	max-width: 32.1rem;


	@include mixins.landscape{
		display: none;
	}
}

.checkbox-filters{
	display: none;

	@include mixins.landscape{
		display: flex;
		max-width: 50rem;
		place-self: center center;
		justify-content: space-evenly;
		width: 100%;
		padding: 0 3%;
		font-size: 1.05rem;

		@include mixins.medium-screen{
			grid-column: 2/3;
		}

		&__category{
			color: var(--blue);
			// margin-right: 1.3rem;
			user-select: none;
			position: relative;
			display: flex;


			&:last-child{
				margin-right: 0;
			}
		}

		&__checkbox{
			opacity: 1 !important;
			border: 2px solid var(--blue);
			height: 1rem;
			width: 1rem;
			z-index: 0 !important;
			float: unset !important;
			margin: 0 0.4rem 0.4rem 0 !important;
			cursor: pointer;
			color: var(--blue);

			&:checked{
				background: var(--blue);
			}
		}
	}
}