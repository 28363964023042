:root {
	--coral: #E57283;
	--blue: #2F5061;
	--teal: #4297A0;
	--ivory: #F4EAE6;
	--background-color: whitesmoke;
	--heading-font: 'Rationale', sans-serif;
	--text-font: 'Montserrat', sans-serif;
	--text-transform: uppercase;
	--menu-font-size: 1.85rem;
	--heading-font-small: 2.4rem;
	--heading-font-big: 3rem;
	--bar-spacing-mobile: 1.6rem;
	--bar-spacing-desktop: 1.85rem;
	--menu-font-mobile: 1.25rem;
}