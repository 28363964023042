@use 'variables';
@use 'mixins';

.about{
	@include mixins.default-grid;
    @include mixins.cube-face;
    background: linear-gradient(var(--blue), var(--teal));

    &__content-box{
        @include mixins.content-box;
        margin-bottom: 60%;
        max-width: 60rem;

        @media (min-width: 415px) {
            margin-bottom: 29.5%;
        }

        @include mixins.landscape {
            margin-bottom: 17%;
        }

        @include mixins.medium-screen {
            margin-bottom: 13%;
        }

        @include mixins.wide-screen {
            max-width: 75rem;
            margin-bottom: 14.25%;
        }

        @include mixins.extra-wide-screen {
            margin-bottom: 10%;
        }

        @include mixins.widest-screen {
            margin-bottom: 17%;
        }
    }

    .heading-row{
        @include mixins.default-grid;

        &__content{
            display: flex;
            place-self: center center;
        }

        &__image{
            @include mixins.default-img;
            place-self: center end;
            grid-row: 1/3;
            grid-column: 1/2;
            height: 7rem;
            width: 7rem;
            border-radius: 0.7rem;
            margin-right: 3.5vw;

            @include mixins.landscape {
                height: 10rem;
                width: 10rem;
                margin-right: 4vw;
            }

            @include mixins.wide-screen {
                height: 16.75rem;
                width: 16.75rem;
            }
        }

        &__text{
            display: flex;
            flex-direction: column;
            justify-content: center;
            
        }

        &__heading{
            @include mixins.page-heading;
            grid-row: 1/2;
            grid-column: 2/3;
            margin-bottom: 0.15rem;

            @include mixins.landscape {
                font-size: 3.75rem;
            }

            @include mixins.wide-screen {
                font-size: 5.75rem;
            }
        }

        &__subheading{
            @include mixins.page-heading;
            grid-row: 2/3;
            grid-column: 2/3;
            font-size: 1.6rem;
            margin-top: 0.15rem;

            @include mixins.landscape {
                font-size: 2.25rem;
            }

            @include mixins.wide-screen {
                font-size: 3.9rem;
            }
        }
    }

    &__text{
        font-size: 1.3rem;
        line-height: 1.4;
        text-align: left;

        @media (min-width: 415px) {
            font-size: 1.1rem;
        }

        @include mixins.landscape {
            font-size: 1.2rem;
        }

        @include mixins.medium-screen {
            font-size: 1.45rem;
        }

        @include mixins.wide-screen{
            font-size: 1.85rem;
        }
    }

    &__links{
        @include mixins.default-grid;
        grid-template-columns: repeat(5,1fr);
    }

    &__link{
        font-size: 1.5rem;
        @include mixins.default-grid;
        position: relative;

        @include mixins.wide-screen {
            font-size: 2.4rem;
        }

        &_linkedin{
            grid-column: 2/3;
            grid-row: 1/2;
            justify-self: center;

            &:hover .about__tooltip_linkedin{
                opacity: 1;
            }
        }

        &_resume{
            grid-column: 4/5;
            grid-row: 1/2;
            justify-self: center;

            &:hover .about__tooltip_resume{
                opacity: 1;
            }
        }
    }

    &__tooltip{
        position: absolute;
        opacity: 0;
        background: rgba(0,0,0,0.2);
        transition: opacity 250ms ease-in-out;
        font-size: 1rem;
        bottom: 1.9rem;
        left: 50%;
        transform: translateX(-50%);
        color: var(--ivory);
        padding: 0 0.3rem;

        @include mixins.wide-screen {
            bottom: 3rem;
            font-size: 1.25rem;
        }

        &:hover{
            color: var(--ivory) !important;
        }
    }
    
}