@use 'variables';

@mixin landscape {
    @media (min-width: 768px){
      @content;
    }
  }
  
@mixin medium-screen {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin wide-screen {
  @media (min-width: 1281px) {
    @content;
  }
}

@mixin extra-wide-screen {
  @media (min-width: 1369px) {
    @content;
  }
}

@mixin widest-screen {
  @media (min-width: 1537px) {
    @content;
  }
}

@mixin default-grid{
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr;
}

@mixin default-img{
  object-fit: cover;
	object-position: center;
}

@mixin default-background{
  background-size: cover;
	background-position: center;
}

@mixin cover-parent{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

@mixin image-slot{
  height: 0;
  position: relative;
}

@mixin cube-face{
  width: 100vw;
  min-height: 100vh;
  padding: 0 2rem;
  grid-auto-rows: max-content;
}

@mixin page-heading{
  text-align: center;
  font-family: var(--heading-font);
  font-size: 2.75rem;
}

@mixin projects-nav-button{
  box-shadow: unset;
  font-family: var(--text-font);
  text-transform: unset !important;
  font-size: 1.5rem;
  z-index: 1;
  color: var(--coral) !important;
  user-select: none;
  height: unset;
  line-height: unset;

  &:hover{

    @media (min-width: 415px) {
      color: var(--blue) !important;
      box-shadow: unset;
    }
    
  }
}

@mixin desktop-nav-button{
  position: unset;
  margin-top: -1rem;
  grid-row: 1/2;
  grid-column: 1/2;
  border: unset;
  font-size: 1.4rem
}

@mixin content-box{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  justify-self: center;
}