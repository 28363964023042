@use 'variables';
@use 'mixins';


.contact{
	@include mixins.default-grid;
    @include mixins.cube-face;
    background: linear-gradient(var(--ivory), var(--coral));

    &__content-box{
        @include mixins.content-box;
		width: 100%;
        max-width: 60rem;
        margin-bottom: 18%;

        @media (min-width: 415px) {
            margin-bottom: 13%;
        }

        @include mixins.landscape {
            margin-bottom: 7%;
        }

        @include mixins.medium-screen {
            margin-bottom: 3%;
        }

        @include mixins.wide-screen {
            margin-bottom: 3.35%;
        }

		@include mixins.extra-wide-screen {
            margin-bottom: 5.25%;
        }
    }

    &__heading{
        @include mixins.page-heading;
        color: var(--teal);
		user-select: none;

        @include mixins.wide-screen {
            margin-top: 1rem;
        }

        @include mixins.extra-wide-screen {
            margin-top: unset;
        }
    }

    &__subheading{
        font-size: 1.3rem;
        line-height: 1.4;
        text-align: left;
        color: var(--teal);
        text-align: center;

        @media (min-width: 415px) {
            font-size: 1.1rem;
        }

        @include mixins.landscape {
            font-size: 1.2rem;
        }

        @include mixins.wide-screen{
            font-size: 1.85rem;
        }
    }

    &__at{
        margin-left: 0.2rem;
        font-size: 1.4rem;
        user-select: none;

        &:before{
            content: "canosaer"
        }

        &:after{
            content: "gmail.com"
        }
    }

    &__separator{
        position: relative;
    }

    &__icon{
        position: absolute;
        top: 0.25rem;
        right: 1rem;
    }

    &__link:hover{
        color: var(--blue) !important;
    }


}

.form{
    &__label{
        font-size: 2rem;
        font-family: var(--heading-font);
        margin-bottom: 0.4rem;
        color: var(--teal);
    }

    &__input{
        width: 100%;
        color: rgba(0,0,0,0.9);
        padding: 0.5rem;
        font-size: 1.25rem;
        font-weight: bold;
        border: 2px solid var(--blue);
        outline: none;
        border-radius: 0.2rem;
        margin-bottom: 1rem;
        line-height: 1.05;
        resize: none;
        background: white;

        &:focus{
            border: 2px solid var(--blue);
        }

        &_subject{
            height: 2.65rem;
        }

        &_message{
            margin-bottom: 1.5rem;
            height: 16rem;

            @include mixins.medium-screen {
                height: 8.75rem;
            }

            @include mixins.wide-screen {
                height: 18rem;
            }
        }
        
    }

    &__error{
        font-size: 1.5rem;
        margin-left: 0.25rem;
    }

    &__button{
        color: var(--ivory);
        background-color: var(--teal);
        padding: 0.5rem 1rem;
        border-radius: 999rem;
        user-select: none;

        &:hover{
            color: var(--ivory) !important;
        }
    }
}